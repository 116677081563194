import React from 'react';
import PageTitle from '../../components/PageTitle/PageTitle';
import Link from '../../components/Link';

const TncEn = ({ gender, isMobileView }) => {
  const genderPath = gender === 'women' ? '/femme' : '';

  const fontFamily = { fontFamily: 'futural, Arial, 微軟正黑體, Helvetica, sans-serif', fontWeight: 'normal' }

  return (
    <div>
      <br />
      {isMobileView ? (
        <PageTitle shadow={gender === 'men' ? true : false}>
          L’Oréal Hong Kong Limited
          <br />
          Terms of Use
        </PageTitle>
      ) : (
        <h1 style={fontFamily}>
          L’Oréal Hong Kong Limited
          <br />
          Terms of Use
        </h1>
      )}
      <br />
      {gender === 'men' ? (
        <div className="content">
          1.Automatic Collection of Anonymous Information
          <br />
          <br />
          When you visit the Biotherm website, like when you visit most other
          websites, certain anonymous information about your visit is
          automatically logged, which may include information about the type of
          browser you use, the server name and IP address through which you
          access the internet (such as ""aol.com"" or ""earthlink.net""), the
          date and time you access the site, the pages you access while at the
          Biotherm website, and the internet address of the website, if any,
          from which you linked directly to the Biotherm site. This information
          is not personally identifiable.
          <br />
          <br />
          2.Personally Identifiable Information
          <br />
          <br />
          Personally Identifiable Information is any information that concerns
          you individually and would permit someone to contact you, for example,
          your name, address, telephone/fax number, social security number,
          email address or any information you submitted to biothermhk.com that
          identifies you individually.
          <br />
          <br />
          Biothermhk.com will not collect any personally identifiable
          information about you unless you provide it. Therefore, if you do not
          want biothermhk.com to obtain any personally identifiable information
          about you, do not submit it. You can visit and browse the Biotherm
          website without revealing personally identifiable information about
          yourself. You may also choose to disclose personally identifiable
          information about yourself, which may be maintained as described
          below. Biothermhk.com may collect personally identifiable information
          about you from its website by methods such as the following:
          <br />
          <br />
          * Registration Forms - If you are offered the opportunity to enter a
          promotion, to become a registered user of the Biotherm website, or to
          opt-in to receive Biotherm information through another site, you must
          apply by filling out the registration form on the site. This form
          requires certain personally identifiable information that may include,
          without limitation, your name, email address, postal address,
          telephone number, areas of interest, product usage, and/or a unique
          individual password.
          <br />
          <br />
          * Transactions and Activity - If you become a registered user or if
          you conduct transactions through the Biotherm website, biothermhk.com
          collects information about the transactions you engage in while on the
          website and your other activity on the site. This information may
          include, without limitation, areas of the website that you visit,
          transaction type, content that you view, download or submit,
          transaction amount, payment, shipping and billing information as well
          as the nature, quantity and price of the goods or services you
          exchange and the individuals or entities with whom you communicate or
          transact business.
          <br />
          <br />
          * Email and other voluntary communications - You may also choose to
          communicate with biothermhk.com through email, via our website, by
          telephone, in writing, or through other means. We collect the
          information in these communications, and such information may be
          personally identifiable.
          <br />
          <br />
          3.Information Use
          <br />
          <br />
          Biothermhk.com may use the personally identifiable information
          collected through its website primarily for such purposes as:
          <br />
          <br />
          * helping to establish and verify the identity of users;
          <br />
          <br />
          * opening, maintaining, administering and servicing users' accounts or
          memberships;
          <br />
          <br />
          * processing, servicing or enforcing transactions and sending related
          communications; <br />
          <br />
          * providing services and support to users; <br />
          <br />* improving the website, including tailoring it to users'
          preferences; <br />
          <br />* providing users with product or service updates, promotional
          notices and offers, and other information about Biotherm and its
          affiliates; <br />
          <br />* responding to your questions inquiries, comments and
          instructions; <br />
          <br />
          * maintaining the security and integrity of its systems. <br />
          <br />
          Biothermhk.com uses the anonymous browsing information collected
          automatically by its servers primarily to help it administer and
          improve its website. Biothermhk.com may also use aggregated anonymous
          information to provide information about its website to advertisers,
          potential business partners and other unaffiliated entities. Again,
          this information is not personally identifiable. <br />
          <br />
          4.Disclosure of Information
          <br />
          <br />
          Biothermhk.com does not provide personally identifiable information to
          unaffiliated third parties for their use in marketing directly to you.
          Biotherm may use unaffiliated companies to help it maintain and
          operate its website or for other reasons related to the operation of
          its business, and those companies may receive your personally
          identifiable information for that purpose. Biotherm may also disclose
          personally identifiable information about you in connection with legal
          requirements, such as in response to an authorized subpoena,
          governmental request or investigation, or as otherwise permitted by
          law. Finally, as Biotherm's business develops, it may sell or buy
          corporate assets, and in such transactions customer information may be
          one of the transferred business assets. If Biotherm, its internet
          businesses, or substantially all of its assets, is acquired, customer
          information may be one of the assets transferred. <br />
          <br />
          5.Children's Privacy
          <br />
          <br />
          This website is directed toward and designed for use by persons aged
          13 or older. Biothermhk.com will not approve applications of, or
          establish or maintain accounts or memberships for, any child whom
          biothermhk.com knows to be under the age of 13. Biothermhk.com does
          not solicit or knowingly collect personally identifiable information
          from children under the age of 13. If biothermhk.com nevertheless
          discovers that it has received personally identifiable information
          from an individual who indicates that he or she is, or whom Biotherm
          otherwise has reason to believe is, under the age of 13,
          biothermhk.com will delete such information from its systems.
          Additionally, a child's parent or legal guardian may request that the
          child's information be corrected or deleted from biothermhk.com's
          files.
          <br />
          <br />
          6."Cookies" and Advertisers
          <br />
          <br />
          The Biotherm website's server, or the servers of companies that are
          used to operate this site, may place a "cookie" on your computer in
          order to allow you to use the website and to personalize your
          experience.
          <br />
          <br />
          A "cookie" is a small piece of data that can be sent by a web server
          to your computer, which then may be stored by your browser on your
          computer's hard drive. Cookies allow biothermhk.com to recognize your
          computer while you are on its website and help customize your online
          experience and make it more convenient for you. Cookies are also
          useful in allowing more efficient log-in for users, tracking
          transaction histories and preserving information between sessions. The
          information collected from cookies may also be used to improve the
          functionality of the website. <br />
          <br />
          The advertisers and/or other content providers that may appear on this
          website may also use cookies that are not sent by the Biotherm
          website. Such ads or content may contain cookies that help track and
          target the interests of users of this website in order to present
          "personalized" advertisements or other messages that the user might
          find interesting. Biotherm is not responsible for any such cookies.{' '}
          <br />
          <br />
          Most web browser applications (such as Microsoft Internet Explorer and
          Netscape Navigator) have features that can notify you when you receive
          a cookie or prevent cookies from being sent. If you disable cookies,
          however, you may not be able to use certain personalized functions of
          this website. <br />
          <br />
          7.External Links <br />
          <br />
          The Biotherm website may contain links to other websites, including
          those of advertisers or third party content providers who offer
          downloads on biothermhk.com. Please be aware that Biotherm is not
          responsible for the privacy practices or the content of other
          websites. Websites that are accessible by hyperlinks from the Biotherm
          website may use cookies [link to "Cookies" section]. Biotherm
          encourages you to read the privacy statements provided by other
          websites before you provide personally identifiable information to
          them. <br />
          <br />
          8.Security <br />
          <br />
          Biothermhk.com maintains physical, electronic, and procedural
          safeguards to help guard personally identifiable information. If
          transactions are offered on the site, transaction information is
          transmitted to and from biothermhk.com in encrypted form using
          industry-standard Secure Sockets Layer (SSL) connections to help
          protect such information from interception. Biothermhk.com restricts
          authorized access to your personal information to those persons who
          have a legitimate purpose to know that information to provide products
          or services to you and those persons you have authorized to have
          access to such information. Please be aware, however, that any email
          or other transmission you send through the internet cannot be
          completely protected against unauthorized interception. <br />
          <br />
          9.Choice/Opt-Out <br />
          <br />
          In order to provide service to you, biothermhk.com will send you
          communications related to your transactions, security or the
          administration of its website. From time to time, biothermhk.com and
          its affiliates may also wish to send you other messages or updates
          about biothermhk.com, its affiliates, or their promotions and other
          activities. If you do not wish to receive non-transaction/security
          related communications from biothermhk.com and its affiliates, please
          unsubscribe. <br />
          <br />
          10.Data Quality/Access <br />
          <br />
          Biothermhk.com allows you to change, update, or delete the information
          you may provide in your optional registration form. If you would like
          to change, update, or delete your personal information, please send
          your request to “Contact Us”. <br />
          <br />
          11.Changes to this Privacy Statement <br />
          <br />
          Biothermhk.com reserves the right to modify or supplement this policy
          at any time. If a material change to the terms of this policy is made,
          biothermhk.com will post a notice on its homepage and a link to the
          new policy. <br />
          <br />
          12.Contacting Us <br />
          <br />
          If you have questions about this privacy statement, the information
          practices of biothermhk.com or your dealings with biothermhk.com,
          please send your request to “Contact Us”. <br />
          <br />
          In accordance with laws governing literary and artistic property
          rights or other similar rights, the reproduction or redistribution of
          the elements that make up the Biotherm website, in whole or in part,
          is strictly prohibited. <br />
          <br />
          13.All of the brands cited herein are registered trademarks. <br />
          <br />
          Biotherm is happy to welcome you to its website. While Biotherm has
          endeavoured to ensure the accuracy of the information accessed via the
          website, Biotherm does not guarantee or give any warranty as to the
          accuracy, timeliness or completeness of any information or material on
          the website, and declines all responsibility for technical
          inaccuracies or other errors. Biotherm declines all responsibility for
          any difficulties encountered in accessing the site or for any
          communication line failure. <br />
          <br />
        </div>
      ) : (
        <div className="content">
          IMPORTANT
          <br />
          <br />
          Thank you for accessing this <Link to="/en">Website</Link>. Please
          read these terms and conditions (the "Terms") before using the Website
          which is operated by 35/F, Sun Hung Kai Centre, 30 Harbour Road, Wan
          Chai, Hong Kong ("L’Oréal Hong Kong Limited"). By using the Website, you signify your
          acceptance of the Terms in consideration of which L’Oréal Hong Kong Limited provides you
          with access. From time to time L’Oréal Hong Kong Limited may modify the Terms.
          Accordingly, please continue to review the Terms whenever accessing or
          using the Website. Your use of the website following any such change
          constitutes your agreement to follow and be bound by the terms as
          changed. If at any time you do not wish to accept the Terms, you may
          not use the Website.
          <br />
          <br />
          NO RELIANCE
          <br />
          <br />
          While L’Oréal Hong Kong Limited has endeavoured to ensure the accuracy of the
          information accessed via the Website, L’Oréal Hong Kong Limited does not guarantee or
          give any warranty as to the accuracy, timeliness or completeness of
          any information or material on the Website.
          <br />
          <br />
          LINKS
          <br />
          <br />
          There may be links that will let you leave this Website or that will
          let you access this Website from third party sites. Linked third party
          sites are not under the control of L’Oréal Hong Kong Limited and L’Oréal Hong Kong Limited is not
          responsible for the contents of any such linked site or any link
          contained in such a linked site.
          <br />
          <br />
          Existence of links to other third party sites is not an endorsement by
          L’Oréal Hong Kong Limited in favour of such site or the products or services contained
          in any linked site. To the extent that this Website contains links to
          or may be accessed from outside services and resources, the
          availability and content of which L’Oréal Hong Kong Limited does not control, any
          concerns regarding any such service or resource or any link thereto
          should be directed to the particular outside service or resource.
          <br />
          <br />
          INTELLECTUAL PROPERTY
          <br />
          <br />
          The Website, including text, content, software, video, music, sound,
          graphics, photographs, illustrations, artwork, photographs, names,
          logos, trademarks, service marks and other material ("Content") is
          protected by copyrights, trade marks and/or other proprietary rights.
          The Content includes both content owned or controlled by L’Oréal Hong Kong Limited and
          content owned or controlled by third parties and licensed to L’Oréal Hong Kong Limited.
          All individual articles, reports, and other elements making up the
          Website may be copyright works. You agree to abide by all additional
          copyright notices or restrictions contained in the Website.
          <br />
          <br />
          You may not use any of L’Oréal Hong Kong Limited's trademarks or trade names without
          L’Oréal Hong Kong Limited's prior express written consent and you acknowledge that you
          have no ownership rights in and to any of those names and marks.
          <br />
          <br />
          You agree to notify L’Oréal Hong Kong Limited in writing promptly upon becoming aware of
          any unauthorised access to or use of the Website by any party or of
          any claim that the Website or any of the contents of the Website
          infringes any copyright, trade mark, or other contractual, statutory
          or common law rights of any party.
          <br />
          <br />
          LICENCE AND DOWNLOAD RIGHTS
          <br />
          <br />
          You acquire no rights or licences in or to the Website and/or the
          Content other than the limited right to use the Website in accordance
          with these Terms and to download on the terms set out in this section.
          Other than as set out in this section you may not copy,reproduce,
          recompile, decompile, disassemble, reverse engineer, distribute,
          publish, display, perform, modify, upload to create derivative works
          from, transmit, or in any other way exploit any part of the Website.
          <br />
          <br />
          Download is permitted by L’Oréal Hong Kong Limited provided only that: <br />
          (i) you make no more than one printed copy of such download and no
          further copies of such printed copy are made;
          <br /> (ii) you make only personal, non-commercial use of such
          download and/or printed copy; and <br />
          (iii) you retain on such download and/or printed copy all copyright
          notices and shall remain bound by the terms of such wording and
          notices. Additionally, you may not offer for sale or sell or
          distribute over any other medium (including distribution by
          over-the-air television or radio broadcast or distribution on a
          computer network) the Content or any part thereof. You may not make
          any part of the Website available as part of another website, whether
          by hyperlink framing on the internet or otherwise. The Website and the
          information contained therein may not be used to construct a database
          of any kind, nor may the Website be stored (in its entirety or in any
          part) in databases for access by you or any third party or to
          distribute any database websites containing all or part of the
          Website.
          <br />
          <br />
          NO WARRANTY
          <br />
          <br />
          The Website and the Content are provided "as is" excluding any
          warranties of any kind, either express or implied, to the fullest
          extent permissible pursuant to applicable law including the exclusion
          of warranties of title, merchantability, satisfactory quality, fitness
          for a particular purpose and non-infringement of proprietary or third
          party rights. L’Oréal Hong Kong Limited further accepts no responsibility or liability
          for functions contained on the Website and makes no warranties that
          the Website will operate uninterrupted or error-free or that defects
          will be corrected. Please note that some jurisdictions do not allow
          the exclusion of implied warranties, so some or all of the above
          exclusions may not apply to you.
          <br />
          <br />
          L’Oréal Hong Kong Limited does not warrant that the Website is compatible with your
          computer equipment or that the Website or its server is free of errors
          or viruses, worms or "Trojan horses" and L’Oréal Hong Kong Limited is not liable for any
          damage you may suffer as a result of such destructive features.
          <br />
          <br />
          L’Oréal Hong Kong Limited shall not be held responsible for Content provided by third
          parties. L’Oréal Hong Kong Limited is also not responsible for the reliability or
          continued availability of the telephone lines and equipment you use to
          access the website.
          <br />
          <br />
          These Terms do not affect your statutory rights or your legal rights
          as a consumer.
          <br />
          <br />
          LIMITATION OF LIABILITY
          <br />
          <br />
          You acknowledge that your use of the Website, including the Content is
          at your own risk. If you are dissatisfied with the Website, the Terms
          or any or the Content your sole remedy is to discontinue use of the
          Website.
          <br />
          <br />
          Save in respect of fraud and of personal injury or death to the extent
          it results from L’Oréal Hong Kong Limited's negligence, in no event will L’Oréal Hong Kong Limited be
          liable to you or any third party for any direct, special, indirect,
          consequential or incidental damages, exemplary or lost profits, or any
          other damages of any kind whether based on warranty, contract, tort
          (including negligence) or otherwise, even if L’Oréal Hong Kong Limited has been advised
          of the possibility thereof, arising from your use of the website.
          Applicable law may not allow the limitation or exclusion of liability
          for consequential or incidental damages, so this limitation or
          exclusion may not apply to you.
          <br />
          <br />
          CHANGES TO THE WEBSITE
          <br />
          <br />
          You accept that L’Oréal Hong Kong Limited has the right to change the content or
          technical specifications of any aspect of the Website at any time at
          L’Oréal Hong Kong Limited's sole discretion. You further accept that such changes may
          result in your being unable to access the website.
          <br />
          <br />
          INDEMNITY
          <br />
          <br />
          You agree to indemnify, defend, and hold harmless each of L’Oréal Hong Kong Limited ,
          its employees, representatives and agents, from and against any
          claims, actions, demands or other proceedings brought against any of
          L’Oréal Hong Kong Limited , its employees, representatives or agents, by a third party,
          to the extent that such claim, suit, action or other proceeding
          brought against L’Oréal Hong Kong Limited , its employees, representatives, suppliers,
          or agents is based on or arises in connection with:
          <br />
          <br />
          (a) your use of the Website; <br />
          (b) any breach by you of the Terms; <br />
          (c) a claim that any use of the Website by you:
          <br />
          (i) infringes any intellectual property rights of any third party, or
          any right of personality or publicity, or
          <br />
          (ii) is libellous or defamatory, or otherwise results in injury or
          damage to any third party;
          <br />
          (d) any deletions, additions, insertions, or alterations to, or any
          unauthorised use of, the Website by you; or <br />
          (e) any misrepresentation or breach of representation or warranty made
          by you contained herein.
          <br />
          <br />
          References in this section of the Terms to your use of the Website
          shall be deemed to include any use by a third party where such a third
          party accesses the Website using your computer. You agree to pay
          L’Oréal Hong Kong Limited , its employees, representatives, and agents any and all
          costs, damages, and expenses (including reasonable legal fees) awarded
          against any of them or otherwise incurred by any of them in connection
          with or arising from any such third party claim, suit, action or
          proceeding attributable to any such third party claim.
          <br />
          <br />
          You agree that L’Oréal Hong Kong Limited may use and/or disclose information about your
          demographics and use of the Website in a manner that does not reveal
          your identity.
          <br />
          <br />
          TRANSMISSION AND FEEDBACK OF INFORMATION
          <br />
          <br />
          Any information you transmit through this Website is subject to
          L’Oréal Hong Kong Limited's privacy policy and remains L’Oréal Hong Kong Limited’s property. You are
          prohibited from transmitting to or from this Website any unlawful,
          threatening, libellous, defamatory, obscene, inflammatory or
          pornographic material or other material that could give rise to any
          civil or criminal liability under law.
          <br />
          <br />
          LOCAL LAWS AND REGULATIONS
          <br />
          <br />
          The Website is not directed at any person in any jurisdiction where
          for any reason the publication or availability of the Website is
          prohibited. Those in respect of whom such prohibitions apply must not
          access the Website.
          <br />
          <br />
          L’Oréal Hong Kong Limited does not represent that either the Website or the Content are
          appropriate for use or permitted by local laws in all jurisdictions.
          Those who access the Website do so on their own initiative and are
          responsible for compliance with applicable local laws or regulations;
          legal advice should be sought in cases of doubt.
          <br />
          <br />
          TERMINATION
          <br />
          <br />
          Either you or L’Oréal Hong Kong Limited may terminate these terms with or without cause
          at any time. If L’Oréal Hong Kong Limited terminates these terms, L’Oréal Hong Kong Limited will email you
          at the address you provide on registration, and you will be deemed to
          have received it within one hour of transmission. Termination will be
          effective at that time. You will be responsible for notifying us of
          any changes to your email address. You may terminate by unsubscribing
          here. On termination you shall destroy all Content, and copies
          thereof, obtained from the website.
          <br />
          <br />
          SEVERANCE AND WAIVER
          <br />
          <br />
          If any provision of this Agreement is found to be invalid by any court
          having jurisdiction, the invalidity of such provision shall not affect
          the validity of the remaining provisions of this Agreement, which
          shall remain in full force and effect. No waiver of any term of this
          Agreement shall be deemed a continuing waiver of such term or any
          other term.
          <br />
          <br />
          THIRD PARTY RIGHTS
          <br />
          <br />
          Nothing in this Agreement is intended to confer on any third party
          (whether referred to in this Agreement by name, class, description or
          otherwise) any benefit or any right (under the Contracts (Rights of
          Third Parties) Ordinance or otherwise) to enforce any provision of
          this Agreement or any agreement entered into in connection with it.
          <br />
          <br />
          SEVERANCE AND WAIVER
          <br />
          <br />
          If any provision of this Agreement is found to be invalid by any court
          having jurisdiction, the invalidity of such provision shall not affect
          the validity of the remaining provisions of this Agreement, which
          shall remain in full force and effect. No waiver of any term of this
          Agreement shall be deemed a continuing waiver of such term or any
          other term.
          <br />
          <br />
          ENTIRE AGREEMENT
          <br />
          <br />
          Except as provided herein, these terms and conditions are the entire
          agreement between us and supersede any prior understanding or
          agreements (written or oral). Nothing in this clause will operate to
          limit or exclude either party’s liability for fraud.
          <br />
          <br />
          GOVERNING LAW AND JURISDICTION
          <br />
          <br />
          The Terms are governed by the laws of Hong Kong and the parties submit
          to the exclusive jurisdiction of the courts of Hong Kong.
          <br />
          <br />
          OLAPIC GALLERY
          <br />
          <br />
          We may make available on this Site a space, powered by Olapic, Inc.
          (“Olapic”) dedicated to user content, such as for example text,
          photos, videos, etc. (“User Content”). Where you upload User Content
          to our site, or respond to our request to use User Content you have
          already posted to social media with #Biotherm, you agree to our
          #Biotherm terms and conditions:{' '}
          <Link to={`/en${genderPath}/tnc`}>olapic-terms-and-conditions</Link>
          .<br />
          <br />
          We do not endorse or control the User Content transmitted or posted on
          the Site and therefore, we do not guarantee the accuracy, integrity or
          quality of User Content. You understand that by using the Site, you
          may be exposed to User Content that is offensive, indecent or
          objectionable to you. Under no circumstances will we be liable in any
          way for any User Content, including, without limitation, for any
          errors or omissions in any User Content, or for any loss or damage of
          any kind incurred by you as a result of the use of any User Content
          transmitted, uploaded, posted, e-mailed or otherwise made available
          via the Site.
          <br />
          <br />
          We have the right to remove any User Content if, in our opinion, such
          material does not comply with these Terms of Use including if the
          content is illegal and/or contrary to the accepted standards of
          morality and/or infringes the rights of third parties.
          <br />
          <br />
          Please inform us of any User Content which appears contrary to
          applicable legislation in force and/or to accepted standards of
          morality and/or the principles set forth herein and/or which infringes
          the rights of others, at the following address{' '}
          <a href="mailto:CorpCom.HK@loreal.com">CorpCom.HK@loreal.com.</a>
          <br />
          <br />
        </div>
      )}
    </div>
  );
};

export default TncEn;
