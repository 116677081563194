import React from 'react';
import PageTitle from '../../components/PageTitle/PageTitle';
import Link from '../../components/Link';

const TncZh = ({ gender, isMobileView }) => {
  const genderPath = gender === 'women' ? '/femme' : '';

  const fontFamily = { fontFamily: 'futural, Arial, 微軟正黑體, Helvetica, sans-serif', fontWeight: 'bold' }

  return (
    <div>
      <br />
      {isMobileView ? (
        <PageTitle shadow={gender === 'men' ? true : false}>
          歐萊雅香港有限公司
          <br /> 網站條款及細則
        </PageTitle>
      ) : (
        <h1 style={fontFamily}>
          歐萊雅香港有限公司
          <br />
          網站條款及細則
        </h1>
      )}

      <br />
      {gender === 'men' ? (
        <div className="content">
          連接及瀏覽本公司網站（以下稱為"本網站"）時，請您先瞭解和接受以下使用條件及相關法規的制約。如果您不同意下列條件，則謝絕您使用本網站。
          <br />
          <br />
          1.使用範圍
          <br />
          <br />
          只要沒有其他特別規定，本公司及相關公司是發佈於本網站上所有內容（以下稱為"本網站內容"）
          的所有者，或者具有發佈內容的權利。使用者可自由瀏覽本網站內容（圖片、聲音及文章等），但是，如果沒有本公司事先書面允許，不得以任何方法、目的來使用本網站內容（包括複製、發佈、變更、向公眾公開、再利用及轉發等）。如果未經本公司許可而擅自使用本網站內容，則有可能構成對著作權法、商標法、肖像權、隱私權的侵害，因此請您必需注意。但是，如果只是出於個人的、非商業性的或是家庭內部使用的目的，可以下載部分本網站內容。但是，本公司不保證客戶使用本網站內容不會侵犯第三者的權利。
          <br />
          <br />
          2.商標類的使用
          <br />
          <br />
          本網站所登載的所有名稱、商標、標誌、服務標記（以下統稱為"商標"），都屬於本公司或相關公司所有，或是基於許可而使用。本網站不容許客戶使用網站內的商標。如果事先沒有獲得本公司或許可者（本公司獲得許可而登載相關內容時）的書面許可，或是根據使用條款明確禁止使用時，嚴格禁止以不正當方法使用本網站所登載的商標或其他內容。
          <br />
          <br />
          3.內容的保證
          <br />
          <br />
          本網站內容包括對未來的預測資訊，本公司努力確保其正確性和最新性。但是不能完全保證其正確性和最新性，因此本公司對於本網站內容相關的任何錯誤及未登載的內容不承擔任何責任。特別是對於對未來的預測資訊，有時因為種種原因，可能會與實際情況出現一些差距。
          <br />
          另外，本網站內容不管明示還是暗示，所提供的內容均不保證對特定目的的適合性、商品性、不侵害性及其它特性。
          <br />
          <br />
          4.免責
          <br />
          <br />
          使用和瀏覽本網站時，由使用者自行承擔風險。本公司及相關人員作為本網站的製作、內容發佈者，對於因連接或使用本網站時所發生的損害（包括因電腦或互聯網系統所產生的損害，不分直接損害和間接損害）及其修理費用不承擔任何責任。
          <br />
          <br />
          5.關於友情鏈結
          <br />
          <br />
          本公司對於從本網站鏈結的第三者的網站（以下稱為"友情鏈結網站"）不承擔確認義務。另外，本公司對於友情鏈結網站的內容及使用者因使用友情鏈結網站而發生的任何損害均不承擔任何責任。從本網站登入到友情鏈結網站時，本公司不能保證該友情鏈結網站的商品及服務，另外本公司也不能保證該友情鏈結網站所登載的資訊的真實性。
          <br />
          <br />
          6.客戶所提供的信息
          <br />
          <br />
          客戶提供給本網站的不包括個人資訊的註冊資訊、建議、資訊及其它內容（以下稱為"提供資訊"），本網站將視為不含秘密的普通資訊來進行處理。資訊提供人應事先同意本公司及相關公司可自由使用所提供的資訊，並且保證使用這些資訊不會侵害任何第三者的權利。
          <br />
          <br />
        </div>
      ) : (
        <div className="content">
          重要事項
          <br />
          <br />
          歡迎瀏覽<Link to="/">網站</Link>
          。使用網站前，請細閱條款及細則﹙「條款」﹚。網站由歐萊雅香港有限公司﹙「歐萊雅」﹚營運，地址為香港灣仔港灣道三十號新鴻基中心三十五樓。閣下使用網站，代表閣下接受條款，以換取歐萊雅提供之瀏覽權限。歐萊雅會不時修訂條款。因此，不論閣下瀏覽或使用網站，請繼續檢視條款。閣下在任何相關變更後使用網站，即同意遵守並受經修訂之條款約束。如閣下在任何時間不欲接受條款，閣下便不能使用網站。
          <br />
          <br />
          免責事項
          <br />
          <br />
          歐萊雅致力確保網站提供的資料準確無誤，惟不就網站任何資料或素材之準確性、時間性或完整性作擔保或任何保證。
          <br />
          <br />
          連結
          <br />
          <br />
          閣下或會因若干連結離開或從第三方網站進入網站。第三方的網站連結並不受控於歐萊雅。歐萊雅亦不會就任何有關連結網站的內容或有關網站之任何連結負責。
          <br />
          <br />
          本網站存在其他第三方網站連結，並不代表歐萊雅認許及支持有關網站或任何連結網站之產品或服務。在本網站包含往返外來服務及資源之連結，而其可用性及內容非由歐萊雅所控的前提下，如就有關服務、資源或任何所載連結有任何疑慮，應查詢特定的外來服務或資源。
          <br />
          <br />
          知識產權
          <br />
          <br />
          網站包括文字、內容、軟件、影片、音樂、聲效、圖案、圖片、說明、藝術圖案、圖片、姓名、標誌、商標、服務商標及其他素材﹙「內容」﹚受版柱、商標及／或其他專利權。內容包括由歐萊雅和第三方擁有或控制、或獲歐萊雅批准的內容。所有個別文章、報告及其他網站所載之元素均屬版權作品。閣下同意遵守網站所列的全部額外版權聲明或限制。
          <br />
          <br />
          閣下未經歐萊雅明確書面同意前，不得使用任何歐萊雅商標或商號，並承認閣下對任何該等名稱及商標並無擁有權。
          <br />
          <br />
          閣下同意如發現任何人士未經授權瀏覽或使用網站，或知悉任何人士因網站或其任何內容觸犯其任何版權、商標或其他合約、法定或普通法權而提出任何申索，均盡早以書面通知歐萊雅。
          <br />
          <br />
          許可及下載權利
          <br />
          <br />
          除網站根據本條款而授予的使用權限，以及就本節所載的條款進行下載外，閣下概無使用網站及／或內容的權利或許可。除本節列明的事項外，閣下不得複印、複製、再編譯、解碼、分拆、還原、發佈、出版、展示、演繹、修訂、上載以製造衍生作品、傳送或以任何其他形式利用網站任何部分。
          <br />
          <br />
          歐萊雅僅在下列情況下允許下載資料︰
          <br />
          (i)閣下不得就有關下載複製多於一份印刷副本，有關印刷副本亦不得複製；
          <br />
          (ii)閣下下載的資料及／或印刷副本僅作個人及非商業用途；及
          <br />
          (iii)閣下保留的該下載及／或印刷副本的所有版權聲明，並繼續受有關措詞及聲明條款約束。此外，閣下不得透過任何其他媒介﹙包括以無線電視、電台廣播或電腦網絡發佈﹚要約出售、販賣或發佈內容或網站所載之任何部分。不論閣下以互聯網的超連結功能或其他方式，也不得將網站任何部分作為其他網站內容。網站及所載資料不可用作建立任何類型的資料庫。網站﹙不論全部或任何部分﹚亦不可存置於資料庫以供閣下或任何第三方瀏覽，或發佈任何載有網站所有或部分資料的資料庫網站。
          <br />
          <br />
          不保證事項
          <br />
          <br />
          網站和內容在「現況」下提供，根據適用法例﹙包括排除擁有權、商業適受性、品質滿意度、特定目的的適用性及不侵犯專利或第三方權利的保證﹚允許之最大程度，排除任何形式﹙不論明示或默示﹚的任何保證。歐萊雅繼而概不就網站所載之功能承擔責任或義務，亦不保證網站營運暢通無阻或準確無誤，或更正有關瑕疵。請注意部分司法管轄範圍概不允許默示保證，因此上述部分或全部排除選項未必對閣下適用。
          <br />
          <br />
          歐萊雅概不保證網站兼容閣下的電腦設備，或網站或其侍服器準確無誤，或不受病毒、蠕蟲或木馬程式影響。歐萊雅概不就閣下因有關破壞性部件而蒙受的任何損失負責。
          <br />
          <br />
          歐萊雅概不就第三方提供之內容負責。歐萊雅亦不會就閣下用作瀏覽網站的線路及設備之可靠性或持續性負責。
          <br />
          <br />
          本條款並不影響閣下作為消費者之法定或法律權利。
          <br />
          <br />
          責任限制
          <br />
          <br />
          閣下知悉閣下須自行承擔使用網站包括內容的一切風險。如閣下對網站、條款或所提供的內容有所不滿，唯一的補救措施是停止使用網站。
          <br />
          <br />
          除非因歐萊雅疏忽而引致的詐騙、個人損傷或死亡，否則無論就任何直接、特殊、間接、衍生或偶發的損害、懲罰性或利益損失或其他根據擔保、合同、侵權（包括疏忽）或任何形式的損害，歐萊雅概不對閣下或任何第三方負責，即使歐萊雅已獲告知因閣下使用網站而產生上述情況之可能性。適用法律未必允許限制或排除衍生或附帶損害的責任，故此免除和限制條款未必適用閣下。
          <br />
          <br />
          網站變更
          <br />
          <br />
          閣下接受歐萊雅有權隨時全權酌情就網站任何部分更改內容或技術規格。閣下繼而接受有關更改或會導致閣下未能瀏覽網站。
          <br />
          <br />
          彌償
          <br />
          <br />
          閣下同意彌償、保障及確保歐萊雅、其僱員、代表及代理人免受任何申索、行動、要求或其他由第三方對歐萊雅、代表或代理提出的訴訟責任，而有關申索、起訴、行動或其他對歐萊雅、其僱員、代表、供應商或代理的訴訟乃基於或源自以下相關情況︰
          <br />
          <br />
          (a)閣下使用網站； <br />
          (b)閣下觸犯任何一項條款； <br />
          (c)因閣下使用網站的而引起的申索︰
          <br />
          (i)侵犯第三方任何知識版權，或人格權或公開權；或
          <br />
          (ii)有損聲譽、誹謗或對第三方造成傷害或損害；
          <br />
          (d)閣下刪除、增加、插入、更改任何資料或未經授權使用網站；或
          <br />
          (e)閣下對本文所載有任何誤解或違反聲明或保證。
          <br />
          <br />
          就本節條款所指，閣下使用網站將被視為包括第三方以閣下電腦瀏覽網站之第三方用途。閣下同意向歐萊雅、其僱員、代表及代理支付任何及所有成本、損害及對任何彼等判處的開支﹙包括合理的法律費用﹚，或其他由相關第三方申索、起訴、行動而對任何彼等產生的任何開支，或因任何相關第三方申索而產生的訴訟費用。
          <br />
          <br />
          資料傳輸及回應
          <br />
          <br />
          閣下透過網站傳送的任何資料受歐萊雅的私隱政策限制，並為歐萊雅資產。閣下不得於本網站發送及發佈任何非法、具威脅性、有損聲譽、誹謗、淫褻、煽動或色情素材，或其他根據法例引起任何民事或刑事責任的素材。
          <br />
          <br />
          閣下同意歐萊雅可透過不記名方式使用及／或披露閣下統計資料及網站用途。
          <br />
          <br />
          本地法例及規例
          <br />
          <br />
          本網站的對象並非位於任何禁止發佈或瀏覽網站所載資料的司法管轄區人士。受此限制之任何人士不得瀏覽本網站。
          <br />
          <br />
          歐萊雅並不代表網站或內容適用或受任何司法管轄區的法例許可。瀏覽網站人士之瀏覽行為均為自發，並有責任遵守適用地區法例或規例。如有疑問，請諮詢法律意見。
          <br />
          <br />
          終止條款
          <br />
          <br />
          閣下或歐萊雅均可隨時﹙不論有否理由﹚終止該等條款。如歐萊雅終止該等條款，將發送電郵至閣下登記之電郵地址。發送後一小時內，閣下將被視為已收取電郵。屆時終止條款隨即生效。閣下的電郵地址如有任何更改，有責任知會我們。閣下可在此取消訂閱以終止條款。條款一經終止，閣下須銷毀所有內容及從網站所得之內容副本。
          <br />
          <br />
          區別及豁免條款
          <br />
          <br />
          如本協議任何條文被任何司法管轄區法院發現無效，該無效條文將不會影響本協議的其餘有效條文，並仍然生效。放棄本協議之任何條款均不得被視為日後免除該條款或其他條款的約束。
          <br />
          <br />
          第三方權利
          <br />
          <br />
          本協議概無條款擬授予任何第三方﹙不論以姓名、階級、描述或其他形式標示於本協議中﹚任何利益或權利﹙根據《合約﹙第三者權利﹚條例》或其他條例﹚執行本協議任何條文或與其有關的任何協議。
          <br />
          <br />
          整體協議
          <br />
          <br />
          除本文所載之條款外，本條款及細則為我們之間的整體協議，取代任何此前達成的諒解或協議﹙不論書面或口頭形式﹚。本條文概不限制或排除任何訂約方因詐騙產生的責任。
          <br />
          <br />
          適用法例和司法管轄區
          <br />
          <br />
          條款受香港法例規管，訂約方須接受香港法院專屬司法管轄權管轄。
          <br />
          <br />
          OLAPIC GALLERY
          <br />
          <br />
          我們或會在本網站設立空間，供刊載用戶內容，如文字、照片、影片等﹙「用戶內容」﹚，並由Olapic,
          Inc.
          ﹙「Olapic」﹚管理。閣下上載用戶內容至我們的網站，或回應我們使用閣下以BIOTHERM上載至社交媒體之用戶內容要求，即同意我們BIOTHERM的條款及細則︰
          <Link to={`${genderPath}/tnc`}>olapic-terms-and-conditions</Link>.
          <br />
          <br />
          我們並不認同或控制傳送或上載至網站的用戶內容，以及保證用戶內容的準確性、真實性或品質。閣下了解使用網站時，或會面對令閣下感到冒犯、淫褻或反感的用戶內容。在任何情況下，我們概不就任何用戶內容以任何形式負責，包括但不限於任何用戶內容之任何錯誤或遺漏，或因任何透過網站傳送、上載、發表、電郵或其他方式使用的用戶內容，而導致閣下蒙受任何損失或任何形式的損害。
          <br />
          <br />
          如我們認為有關素材違反該等使用條款，包括內容屬非法性質及／或違背社會接受的道德標準及／或侵犯第三方權利，我們有權移除任何用戶內容。
          <br />
          <br />
          如閣下發現任何用戶內容可能違反生效之適用法例及／或違背社會接受的道德標準及／或本文所載之原則及／或侵犯其他人士之權利，請發送電郵至
          <a href="mailto:CorpCom.HK@loreal.com">CorpCom.HK@loreal.com</a>
          通知我們。
          <br />
          <br />
        </div>
      )}
    </div>
  );
};

export default TncZh;
